@import 'src/scss/variables';

.Navigation {
  position: fixed;
  bottom: 0;
  left: rem(5px);
  right: 0;
  z-index: 1000;
  background: $colourTertiary;

  .Items {
    ul {
      display: flex;
      flex-flow: row nowrap;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 0;
    }
  }
}

.Item {
  color: $colourQuaternary;
  overflow: hidden;
  height: rem(40px);
  min-width: rem(40px);
  padding: 0 rem(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fsMenu;
  font-family: $fontSecondary;

  @include media("<tablet") {
    padding: 0 rem(10px);
    font-size: rem(14px);
  }

  &.current {
    color: $colourQuaternary;
    background: $colourSecondary;
  }
}

.CommandLine {
  display: flex;
  flex-flow: row nowrap;
  background: $colourPrimary;
  color: $colourQuaternary;
  line-height: 2;
  vertical-align: middle;
  padding: $wsElement;
  padding: 0 rem(10px);


  .SessionName {
    strong {
      color: $colourSecondary;
    }
  }

  input[name="command"] {
    width: 100%;
    border: 0;
    padding: 0;
    margin-left: rem(15px);
    background: transparent;
    color: inherit;

    &:focus {
      outline-color: $colourSecondary;
    }
  }
}
