/**
  * Set rem baseline. By increasing or decreasing the value,
  * the site can scale perfectly. Users with bad vision might've set a minimum font size
  * which is larger than this, and the site will appear larger to them.
  *
  * Use the rem() function in all* px dimensions.
 */
 html {
  font-size: $fsHtml;
  font-family: $fontSecondary;
  line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontSecondary;
  line-height: 1.2;
  font-weight: 700;
  color: inherit;
  margin: 0;
  margin: $wsRow;
}

h1 {
  font-size: $fsH1;

  @include media("<tablet") {
    font-size: rem(32px)
  }
}

h2 {
  font-size: $fsH2;

  @include media("<tablet") {
    font-size: rem(28px)
  }
}

h3 {
  font-size: $fsH3;

  @include media("<tablet") {
    font-size: rem(24px)
  }
}

h4 {
  font-size: $fsH4;

  @include media("<tablet") {
    font-size: rem(22px)
  }
}

h5 {
  font-size: $fsH5;
}

h6 {
  font-size: $fsH6;
}

p {
  font-family: $fontPrimary;
  font-size: $fsP;
  margin: 0;
  margin: $wsRow;
  max-width: rem(800px);
}

ul, ol {
  font-family: $fontPrimary;
  font-size: $fsP;
  margin: $wsRow;
  padding: 0;
  padding-left: 2rem;

  li {
    // padding: $wsElement;
    padding: 0;
    line-height: 1.5;
  }
}

a {
  text-decoration: none;
}

svg:not(:root) {
  width: 100%;
  height: auto; // IE11 will laugh at this and fail
}

.button,
button {
  background: $colourTertiary;
  color: $colourQuaternary;
  transition: 0.3s $easeInQuint;

  &:focus, &:hover {
    background: $colourSecondary;
    transition: 0.3s $easeOutQuint;
  }
}
