@import 'src/scss/variables';

.Errors {
  @include container();
  @include firstElementPadding();
}

.Page404 {
  text-align: center;

  @include container();
  @include firstElementPadding();
}

.Page401 {
  text-align: center;

  @include container();
  @include firstElementPadding();
}
