@import 'src/scss/variables';

.Scrambler {
  display: inline-block;
  position: relative;
  // font-family: monospace;
  white-space: nowrap;
  color: magenta;
  color: $colourSecondary;

  &-scrambled {
    position: relative;
    top: 0;
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }

  &-original {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
  }

  &:hover, &:focus, &:active {
    &-scrambled {
      opacity: 0;
    }
  }
}
