@import 'scss/variables';

.App {
  min-height: 100vh;

  position: relative;
  color: $colourQuaternary;
  background: $colourPrimary;
  // transform-style: preserve-3d; // This breaks all fixed position stuff inside
  border-left: rem(5px) solid $colourSecondary;
  min-height: 100%;
}

.init-text {
  .Typewriter {
    font-family: $fontSecondary;
    // font-family: monospace;
    // white-space: pre;
  }

  font-size: $fsP;
  padding: rem(20px);
  display: block;
}

.container {
  @include container();
}
