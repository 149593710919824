@function rem($size, $fs: $fsHtml) {
  @return #{$size / $fs}rem;
}

@mixin remify($property, $size,	$fs: $fsHtml) {
	#{$property}: rem($size, $fs);
}

@mixin container($maxDesktop: 900px, $maxMobile: 90%) {
  margin: 0 auto;
  // padding: $wsSection;

  > * {
    max-width: $maxMobile;
    margin-left: auto;
    margin-right: auto;

    @include media(">desktop") {
      max-width: rem(900px);
    }
  }
}

@mixin firstElementPadding() {
  padding: $wsSection;
  padding-top: 15%;

  @media (min-width: 1500px) {
    padding-top: rem(200px);
  }
}



@import '~include-media/dist/include-media';
