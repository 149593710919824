@import 'src/scss/variables';

.BlockPlaceholder {
  width: 100%;

  .block {
    width: 100%;
    background: $colourQuaternary;
    // animation: show 0.3s, pulse 0.5s infinite;
    animation: pulse 1s infinite both;
    margin-bottom: rem(20px);

    &--50 {
      height: rem(50px);
    }

    &--75 {
      height: rem(75px);
    }

    &--100 {
      height: rem(100px);
    }


    &--200 {
      height: rem(200px);
    }

    &--300 {
      height: rem(300px);
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
