// SCSS imports are a mess.
@import '~normalize.css/normalize.css';
@import '~@reach/skip-nav/styles.css';

@import 'scss/variables';
@import 'scss/animations';

html {
  box-sizing: border-box;
  // scroll-behavior: smooth;

  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  // min-height: calc(100vh + 1px); // Always show scrollbar, also adds a tiny jitter (which is intentional in this case)
}

html, body, #root {
  height: 100%; // mandatory to allow components to stretch with percentages
}

#root noscript {
  @include firstElementPadding();
  text-align: center;

  > * {
    margin-left: auto;
    margin-right: auto;
  }
}

* {
  scrollbar-color: $colourSecondary $colourQuinary;
  scrollbar-width: 5px;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: $colourQuinary;
}

*::-webkit-scrollbar-thumb {
  background: $colourSecondary;
}

*, *:before, *:after {
  box-sizing: inherit;
}

*:focus {
  outline-color: $colourSecondary;
}

.focus-trap {
  &:focus {
    outline: 0; // Yeah no thanks
  }
}


img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

@import 'scss/typography'
