@import 'src/scss/variables';

.WordPress {
  @include firstElementPadding();

  @include media("<tablet") {
    padding-top: rem(40px);
  }

  background: $colourPrimary;

  &.loading {
    left: rem(5px);
    top: 0;
    right: 0;
    position: fixed;

    // background: rgba($colourPrimary, 1);
    z-index: 100000;
  }

  &.More {
    left: 0vw;
    top: 0vh;
    height: 100vh;
    width: 100vw;
    position: fixed;

    background: rgba($colourPrimary, 1);
    z-index: 100000;
    overflow-y: scroll;
    padding-top: 0;
    padding-bottom: 0;

    button {
      height: rem(50px);
      display: block;
      width: 100%;
      border: 0;
      appearance: none;
      position: fixed;
      bottom: 0;
    }

    &:focus {
      outline: 0;
    }
  }

  &Content {
    padding-bottom: rem(50px);

    > * {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      text-align: left;

      @include media(">desktop") {
        max-width: rem(900px);
      }
    }
  }

  &Header {
    text-align: center;
    max-width: 100%;
    // min-height: 400px;
    position: relative;

    h1 {
      margin-top: 0;

      @include media("<tablet") {
        text-align: left;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.More &Header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: $colourQuinary;
    margin-bottom: rem(50px);

    h1 {
      margin: 0;
      // padding: $wsElement;
      padding: rem(15px);
      text-align: left;
    }

    .FeaturedImage {
      flex-basis: rem(300px);
    }
  }

  &.More &Content {
    padding-bottom: rem(100px);
  }


  a {
    color: $colourSecondary;
  }

  p {
    min-height: 1px;
  }

  // Align inline-block elements to the center, as margin: 0 auto won't work
  text-align: center;

  .aligncenter {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    // display: inline-block;
  }

  .alignwide {
    max-width: $widthBlockWide;
  }

  .alignfull {
    max-width: $widthBlockFull;
   }

  .wp-embed-aspect {
    &-16-9 {
      .wp-block-embed__wrapper {
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;

        iframe {
          height: 100%;
          max-width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.AdminAdditions {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;

  a {
    color: inherit;
    display: inline-block;
    padding: rem(15px);
  }
}

.page-\/ .WordPress {
  @include media("<tablet") {
    padding-top: rem(0px);
  }

  &Header {
    h1 {
      margin-top: rem(20px);
      text-align: center;
    }

    @include media("<tablet") {
      h1 {
        text-align: center;
      }

      img {
        width: 100%;
      }
    }
  }

  p {
    text-align: center;
  }
}
