@import 'src/scss/variables';

.TransitionGroup {
  flex: 1;
  position: relative;
}

.RouteTransition {
  // &-enter {
  &-enter,
  &-appear {
    opacity: 0;
  }

  &-enter-active,
  &-appear-active {
  // &-enter-active {
    opacity: 1;
    transition: opacity 450ms ease-in;
  }

  &-exit {
    // position: absolute;
    // opacity: 0;
    display: none;
  }

  &-exit-active {
    display: none;
  }

  &-exit-done {
    display: none;
  }
}

// Animate /more paths differently
// This is very limited & hacky, as the /more paths use the same router
// as their parent, meaning that whenever a /more path is transitioning in, the parent
// is transitioning out.
// Would be nice if it worked but I've wasted enough time on it.

/* [class*="\/more"].RouteTransition {
// .ModalTransition {
  &-enter .WordPress.More,
  &-appear .WordPress.More {
    opacity: 0;
    transform: translateY(100vh);
  }

  &-enter-active .WordPress.More,
  &-appear-active .WordPress.More {
    animation: fadeInSlideFromRight 500ms $easeOutQuint both;
  }

  &-enter-done .WordPress.More,
  &-appear-done .WordPress.More {
    opacity: 1;
    // transform: translateY(0);
  }

  &-exit .WordPress.More {
    opacity: 1;
    // transform: translateX(0);
  }

  &-exit-active .WordPress.More {
    animation: fadeOutSlideToLeft 500ms $easeInQuint both;
  }

  &-exit-done .WordPress.More {
    opacity: 0;
  }
}

@keyframes fadeInSlideFromRight {
  0% {
    opacity: 0;
    transform: translateX(100vw);
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutSlideToLeft {
  0% {
    opacity: 1;
    transform: translateX(0vw);
  }

  70% {
    // opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(-100vw);
  }
}

 */
