@import 'src/scss/variables';

.Hack {
  background-image: url('../random/hackerman.png');
  background-attachment: fixed;
  background-size: contain;
  min-height: 100vh;
  min-width: 100vw;
  padding: $wsSection;

  > * {
    padding: rem(10px) rem(20px);
    margin-bottom: rem(10px);
    background: rgba($colourQuinary, 0.7);
  }
}
