$fontPrimary: unquote("'Source Sans Pro', sans-serif");
$fontSecondary: unquote("'Cutive Mono', monospace");

// created @ http://colormind.io/bootstrap/
$colourPrimary: #0d0d0e;
$colourSecondary: #f2218a;
$colourTertiary: #004984;
$colourQuaternary: #f2f7f7;
$colourQuinary: #232028;

$fsHtml: 16px;

@import 'src/scss/mixins';

$fsH1: rem(50px);
$fsH2: rem(40px);
$fsH3: rem(35px);
$fsH4: rem(30px);
$fsH5: rem(25px);
$fsH6: rem(20px);
$fsP: rem(18px);
$fsPBig: rem(22px);
$fsPSmall: rem(14px);
$fsMenu: rem(20px);

$wsSection: rem(40px) 0 rem(60px) 0;
$wsRow: rem(15px) 0 rem(15px) 0;
$wsElement: 0 rem(20px);

$widthBlockDefault: rem(800px);
$widthBlockWide: rem(1200px);
$widthBlockFull: 100%;

$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$someRandomEasing: cubic-bezier(0.455, 0.03, 0.515, 0.955);
